jQuery(document).ready(function ($) {

  // Slides
  // var swiper = new Swiper('.swiper-container', {
  //   slidesPerView: 'auto',
  //   centeredSlides: true,
  //   paginationClickable: true,
  //   pagination: {
  //     el: '.swiper-container .swiper-pagination',
  //     type: 'bullets',
  //     clickable: true,
  //   },
  //   navigation: {
  //     nextEl: ".swiper-container .swiper-button-next",
  //     prevEl: ".swiper-container .swiper-button-prev",
  //   },
  //   //loop: true,
  //   // breakpoints: {
  //   //     // when window width is <= 320px
  //   //     1024: {
  //   //       slidesPerView: 2,
  //   //     },
  //   //     // when window width is <= 480px
  //   //     768: {
  //   //       slidesPerView: 1,
  //   //       spaceBetween: 20
  //   //     }
  //   //   }
  // });

  var swiper = new Swiper('.js-gallery', {
    slidesPerView: 'auto',
    centeredSlides: true,
    paginationClickable: true,
    pagination: {
      el: '.js-gallery .swiper-pagination',
      type: 'bullets',
      clickable: true,
    }
  });

  // Header
  $(window).on('scroll', function() {
    const $html = $('html'),
          $this = $(this);
    
    if ($this.scrollTop() != 0) {
      $html.addClass('is-active-header')
    } else {
      $html.removeClass('is-active-header')
    }
  });

  // Remove action
  $('.js-no-action>a').on('click', function(e) {
    e.preventDefault();
  });

  // Menu mobile
  $('.js-menu-mobile').on('click', function(e) {
    const $html = $('html');

    $html.toggleClass('is-active-menu');
  });

  // Play video yt
  $('.js-video-player').on('click', function() {
    const $video = $(this),
          videoSrc = $video.data('video'),
          videoPlayer = $video.children('.js-video-play');
    
    $video.addClass('is-playing');
    videoPlayer.attr('src', videoSrc);
  });

  // Modal
  $('.js-open-modal').on('click', function(e) {
    e.preventDefault();
    const id = $(this).attr('href');

    $(id).addClass('is-visible-modal');
  });

  $('.c-modal').on('click', function(e) {
    if( $(e.target).is('.js-close-modal') || $(e.target).is('.c-modal') ) {
      e.preventDefault();
      $(this).removeClass('is-visible-modal');
    }
  });

  $(document).keyup(function(e) {
    if(e.which=='27'){
      $('.c-modal').removeClass('is-visible-modal');
    }
  });

  // Animeted on scroll
  $(window).on('scroll', function() {
    const winHeight = $(this).innerHeight(),
          winScroll = $(this).scrollTop() + winHeight;

    $('.js-animated').each(function() {
      const $this = $(this),
            elTop = $this.offset().top;

      if(winScroll >= elTop + winHeight / 3) {
        $this.addClass('is-visible');
      }
    });
  });

  // Animated start
  function start() {
    $('.js-animated-start').addClass('is-visible');
  }

  start();

  // MASKED INPUT
  $(".js-data").mask("99/99/9999");
  $(".js-cpf").mask("999.999.999-99");
  $(".js-cep").mask("99999-999");
  $(".js-cnpj").mask("99.999.999/9999-99");
  $('.js-phone').focusout(function(){
    var phone, element;
    element = $(this);
    element.unmask();
    phone = element.val().replace(/\D/g, '');
    if(phone.length > 10) {
      element.mask("(99) 99999-999?9");
    } else {
      element.mask("(99) 9999-9999?9");
    }
  }).trigger('focusout');

  $('.js-timeline').on('click', function() {
    $('.js-timeline').removeClass('is-active');
    $('.c-timeline__text').removeClass('is-active');
    let $year = $(this).attr('data-year');

    $('#' + $year).addClass('is-active');
    $(this).addClass('is-active');
  });

  function carousel() {
    const carousel = document.querySelector('.c-carousel');
    const slideWidth = document.querySelector('.c-carousel__item').offsetWidth;
    const carouselWrapper = document.querySelector('.c-carousel__wrapper');
    const prev = document.querySelector('.c-carousel__button--prev');
    const next = document.querySelector('.c-carousel__button--next');

    let isDragging = false;
    let startPosition;
    let currentTranslate = 0;
    let prevTranslate = 0;

    // // Dragging with mouse
    // carousel.addEventListener("mousedown", e => {
    //   isDragging = true;
    //   startPosition = e.clientX;
    //   carousel.style.cursor = "grabbing";
    //   currentTranslate = getTranslateX();
    // });

    // carousel.addEventListener("mousemove", e => {
    //   if (isDragging) {
    //     const delta = e.clientX - startPosition;
    //     carouselWrapper.style.transform = `translateX(${currentTranslate + delta}px)`;
    //   }
    // });

    // carousel.addEventListener("mouseup", e => {
    //   if (isDragging) {
    //     isDragging = false;
    //     carousel.style.cursor = "grab";
    //     prevTranslate = currentTranslate;
    //     currentTranslate = getTranslateX();
    //     checkProbs();
    //   }
    // });

    // // Dragging with touch
    // carousel.addEventListener("touchstart", e => {
    //   isDragging = true;
    //   startPosition = e.touches[0].clientX;
    //   currentTranslate = getTranslateX();
    // });

    // carousel.addEventListener("touchmove", e => {
    //   if (isDragging) {
    //     const delta = e.touches[0].clientX - startPosition;
    //     carouselWrapper.style.transform = `translateX(${currentTranslate + delta}px)`;
    //   }
    // });

    // carousel.addEventListener("touchend", e => {
    //   if (isDragging) {
    //     isDragging = false;
    //     prevTranslate = currentTranslate;
    //     currentTranslate = getTranslateX();
    //     checkProbs();
    //   }
    // });

    // Arrows
    prev.addEventListener('click', () => {
      currentTranslate += slideWidth;
      carouselWrapper.style.transform = `translateX(${currentTranslate}px)`;
      prevTranslate = currentTranslate;
      checkProbs();
    });

    next.addEventListener('click', () => {
      currentTranslate -= slideWidth;
      carouselWrapper.style.transform = `translateX(${currentTranslate}px)`;
      prevTranslate = currentTranslate;
      checkProbs();
    });

    // Bounding
    const checkProbs = () => {
      const wrapperWidth = carouselWrapper.offsetWidth;
      const wrapperTranslate = getTranslateX();
      const minTranslate = -(wrapperWidth - carousel.offsetWidth);
      const maxTranslate = 0;
      
      if (wrapperTranslate < minTranslate) {
        currentTranslate = minTranslate;
      } else if (wrapperTranslate > maxTranslate) {
        currentTranslate = maxTranslate;
      }
      
      carouselWrapper.style.transform = `translateX(${currentTranslate}px)`;
    };

    const getTranslateX = () => {
      const style = window.getComputedStyle(carouselWrapper);
      const matrix = new WebKitCSSMatrix(style.transform);
      return matrix.m41;
    };
  }

  if( $('.c-carousel') ) {
    carousel();
  }
});